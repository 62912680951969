import { FormEvent, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Outlet, useNavigate, useOutlet } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import moveStep from 'hooks/moveStep';
import { workflowActions, workflowSelectors } from 'state/workflow';
import { authActions, authSelectors } from 'state/auth';
import FormField from 'containers/FormField/FormField';
import {
  VALUE_TOO_LONG,
  VALUE_NAME,
  VALUE_TOO_SHORT,
  ONLY_NUMBERS,
  DO_NOT_WORRY,
  INVALID_EMAIL,
  DOES_NOT_MATCH_REQUIRMENTS,
} from 'constants/validationMessages';
import InfoBar from 'components/InfoBar/InfoBar';
import ProgressBar from 'components/Progress/ProgressBar';
import NameHeader from 'components/nameHeader/NameHeader';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import PrescribingLocation from 'components/PrescribingLocation/PrescribingLocation';
import useIdentifierNavigation from 'hooks/useIdentifierNavigation';
import { formsSelectors, formsActions } from 'state/forms';
import LoadingContent from 'components/LoadingContent/LoadingContent';
import { ERROR_INVALID_REFRESH_TOKEN_REQUEST } from 'constants/errorCodes';
import { emailRegex } from 'constants/validationRules';
import onClear from 'helpers/onClear';
import setAlertMessage from 'helpers/setAlertMessage';
import { ConfigurableHyperLinkSelector } from 'state/hyperlink/hyperlink.selectors';
import ProviderInstructionViewModal from 'containers/views/ProviderInstruction/ProviderInstructionViewModal/ProviderInstructionViewModal';
import PersonalAssistant from 'components/PersonalAssistant/PersonalAssistant';
import PasswordChecklist from 'components/PasswordChecklist/PasswordChecklist';
import {
  containsAlphaCharacter,
  containsUserData,
  isPasswordCorrectLength,
  matchesWitchConfirmPassword,
} from 'components/PasswordChecklist/passwordChecks';

interface LinkComponentProps {
  isDisabled: boolean;
  label: string;
  className?: string;
  href?: string | '';
  target?: string;
}

const LinkComponent = ({ isDisabled, label, ...props }: LinkComponentProps) => {
  if (isDisabled) {
    return <a id={`${label.replaceAll('', ':').toLowerCase()}-anchor-register`}>{label}</a>;
  }

  return (
    <a
      id={`${label.replaceAll(' ', '-').toLowerCase()}-anchor-register`}
      {...props}
    >
      {label}
    </a>
  );
};

const RegisterProvider = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const outlet = useOutlet();

  const form = useAppSelector(workflowSelectors.workflowForm);
  const error = useAppSelector(workflowSelectors.errorSelector);
  const isLogin = useAppSelector(authSelectors.isLoginSelector);
  const formValues = useAppSelector(formsSelectors.formSelector);
  const defaultLocationKey = useAppSelector(formsSelectors.defaultLocationKeySelector);
  const hyperlinks = useAppSelector(ConfigurableHyperLinkSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const alertMessage = setAlertMessage(error);

  const methods = useForm<typeof formValues>({
    defaultValues: formValues,
  });

  useIdentifierNavigation();

  useEffect(() => {
    if (isLogin) {
      navigate('/workflow');
      return;
    }

    const errorCode = !Array.isArray(error) && typeof error === 'object' && error?.code;

    if (errorCode === ERROR_INVALID_REFRESH_TOKEN_REQUEST) {
      dispatch(workflowActions.setError(false));
    }

    methods.setValue('provider:location:isDefault', 'Set Location As Default');
    dispatch(formsActions.setDefaultLocationKey('provider:location:isDefault'));

    dispatch(authActions.initRegister());
  }, []);

  useEffect(() => {
    if (error) {
      setIsDisabled(false);
      window.scrollTo(0, 0);
      setIsLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (methods.formState.isSubmitted) {
      setIsSubmitted(true);
    }
  }, [methods.formState.isSubmitted]);

  useEffect(() => {
    const forms = methods.getValues();
    if (forms && methods.formState.isSubmitted)
      Object.keys(forms).forEach((key) => {
        if (key && key.includes('provider:location:isDefault')) {
          if (!defaultLocationKey || defaultLocationKey === key) {
            methods.trigger(key);
          } else if (defaultLocationKey !== key) {
            methods.resetField(key);
            methods.setValue(key, false);
          }
        }
      });
  }, [defaultLocationKey]);

  const step = 0;
  const { onNextClick } = moveStep();

  const onSubmit = (data: object) => {
    setIsDisabled(true);
    setIsLoading(true);
    const formId = form.uuid || '';
    if (error) {
      dispatch(formsActions.setLocationFormValues({ formId, values: data }));
      dispatch(workflowActions.workflowRetryLatestActionStep());
    } else {
      dispatch(formsActions.setLocationFormValues({ formId, values: data }));
      onNextClick(form.uuid, data);
    }
    dispatch(workflowActions.setError(false));
  };

  const onCancelClick = (e: FormEvent) => {
    e.preventDefault();
    dispatch(authActions.loggingOut());
    navigate('/');
  };

  const [
    firstNameValue,
    middleNameValue,
    lastNameValue,
    npiValue,
    passwordValue,
    confirmPasswordValue,
  ] = useWatch({
    name: [
      'provider:firstName',
      'provider:middleInitial',
      'provider:lastName',
      'provider:npi',
      'provider:password',
      'provider:confirmPassword',
    ],
    control: methods.control,
  });

  useEffect(() => {
    if (!hyperlinks?.['responsive_password_validation']) return;

    if (methods.formState.isSubmitted) {
      methods.trigger('provider:password');
    }
  }, [
    firstNameValue,
    middleNameValue,
    lastNameValue,
    npiValue,
    passwordValue,
    confirmPasswordValue,
    methods.control,
  ]);

  const registrationWrapperClass = hyperlinks?.['responsive_password_validation']
    ? 'flex-column-reverse'
    : 'flex-column';

  const suffixSizeClass = hyperlinks?.['suffix_rename_to_professional_designation']
    ? 'col-md-2'
    : 'col-md-1';

  const npiSizeClass = hyperlinks?.['suffix_rename_to_professional_designation']
    ? 'col-md-2'
    : 'col-md-3';

  if (outlet) {
    return <Outlet />;
  }

  return (
    <>
      <PersonalAssistant autoopenTimeInSeconds={60} />

      <NameHeader serviceType={false} />
      <div className="container mt-2">
        <ProgressBar
          steps={[
            { id: 0, label: 'Initial Onboarding' },
            { id: 1, label: 'Identity Proofing' },
            { id: 2, label: 'EPCS Registration' },
            { id: 3, label: 'Logical Access Control (LAC)' },
          ]}
          activeStep={step}
        />
        <div className="row g-o">
          {!form && !error ? (
            <LoadingContent />
          ) : (
            <FormWrapper
              text={`Step ${step + 1} of 4: Initial Onboarding`}
              type="provider"
              disableError
              errorSubTitle={DO_NOT_WORRY}
            >
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="card-body p-0">
                    <div className="row g-0 pb-1">
                      <div className="col-12 p-3 bg-light">
                        <h5 className="text-info fw-bold">
                          Welcome to the XYWAV and XYREM REMS ePrescription Onboarding Process
                        </h5>
                        <div>
                          In order to electronically prescribe XYWAV and XYREM securely, prescribers
                          must be certified in the XYWAV and XYREM REMS prior to completing a
                          DEA-compliant onboarding process, known as the Electronic Prescribing for
                          Controlled Substances (EPCS). Complete the XYWAV and XYREM REMS Prescriber
                          Certification by clicking{' '}
                          <a
                            id="complete-prescriber-cert-here-anchor-register"
                            href={hyperlinks['configurable_hyperlink_1'] || ''}
                            target="_blank"
                          >
                            here
                          </a>
                          <span className="text-end">
                            <LinkComponent
                              isDisabled={isDisabled}
                              label="Learn More"
                              className="ms-2 btn btn-sm btn-primary btn-provider fw-bold"
                              data-bs-toggle="modal"
                              data-bs-target="#rcopiaModal"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row px-3 py-1">
                      <div
                        id="error"
                        className="col-12"
                      >
                        {error && (
                          <div className="d-flex justify-content-center">
                            <Alert variant={'danger'}>{alertMessage}</Alert>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={`d-flex ${registrationWrapperClass}`}>
                      <div className="row px-3 py-1">
                        <div className="col-12">
                          <h5 className="py-2 text-info">Login Credentials Setup </h5>
                          <p>
                            The onboarding invite will be sent to the email address provided.
                            Remember your password for subsequent login into the XYWAV and XYREM
                            REMS ePrescribing Portal.
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <FormField
                            type="text"
                            identifier="provider:email"
                            isDisabled={isDisabled}
                            validation={{
                              pattern: {
                                value: emailRegex,
                                message: INVALID_EMAIL,
                              },
                            }}
                          />
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <FormField
                            type="text"
                            variant="password"
                            identifier="provider:password"
                            isDisabled={isDisabled}
                            validation={{
                              validate: (value: string) => {
                                if (!hyperlinks?.['responsive_password_validation']) return true;

                                const values = methods.getValues();
                                if (!isPasswordCorrectLength(value || ''))
                                  return DOES_NOT_MATCH_REQUIRMENTS;
                                if (!containsAlphaCharacter(value || ''))
                                  return DOES_NOT_MATCH_REQUIRMENTS;
                                if (
                                  !matchesWitchConfirmPassword(
                                    value,
                                    values['provider:confirmPassword'],
                                  )
                                )
                                  return DOES_NOT_MATCH_REQUIRMENTS;
                                if (
                                  containsUserData(value || '', {
                                    name: values['provider:firstName'] || '',
                                    middleName: values['provider:middleInitial'] || '',
                                    lastName: values['provider:lastName'] || '',
                                    NPI: values['provider:npi'] || '',
                                  })
                                )
                                  return DOES_NOT_MATCH_REQUIRMENTS;

                                return true;
                              },
                            }}
                          />
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <FormField
                            type="text"
                            variant="password"
                            identifier="provider:confirmPassword"
                            isDisabled={isDisabled}
                          />
                        </div>
                        {hyperlinks?.['responsive_password_validation'] && (
                          <PasswordChecklist
                            password={passwordValue || ''}
                            confirmPassword={confirmPasswordValue || ''}
                            userData={{
                              name: firstNameValue || '',
                              middleName: middleNameValue || '',
                              lastName: lastNameValue || '',
                              NPI: npiValue || '',
                            }}
                          />
                        )}
                      </div>
                      <div className="p-1">
                        <hr className="text-muted" />
                      </div>
                      <div className="row px-3 py-1 align-items-end">
                        <div className="col-12">
                          <h5 className="py-2 text-info">Provider Details</h5>
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <FormField
                            type="text"
                            identifier="provider:firstName"
                            isDisabled={isDisabled}
                            validation={{
                              pattern: {
                                value: /^[a-z A-Z&)/('.,-]*$/,
                                message: VALUE_NAME,
                              },
                              maxLength: {
                                value: 35,
                                message: VALUE_TOO_LONG,
                              },
                            }}
                          />
                        </div>
                        <div className="col-sm-6 col-md-2">
                          <FormField
                            type="text"
                            identifier="provider:middleInitial"
                            isDisabled={isDisabled}
                            validation={{
                              pattern: {
                                value: /^[a-z A-Z&)/('.,-]*$/,
                                message: VALUE_NAME,
                              },
                              maxLength: {
                                value: 35,
                                message: VALUE_TOO_LONG,
                              },
                            }}
                          />
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <FormField
                            type="text"
                            identifier="provider:lastName"
                            isDisabled={isDisabled}
                            validation={{
                              pattern: {
                                value: /^[a-z A-Z&)/('.,-]*$/,
                                message: VALUE_NAME,
                              },
                              maxLength: {
                                value: 35,
                                message: VALUE_TOO_LONG,
                              },
                            }}
                          />
                        </div>
                        <div className={`col-sm-6 ${suffixSizeClass} position-relative`}>
                          <FormField
                            type="text"
                            identifier="provider:suffix"
                            labelOverride={
                              hyperlinks?.['suffix_rename_to_professional_designation']
                                ? 'Professional Designation'
                                : undefined
                            }
                            typeahead={[
                              'MD',
                              'DO',
                              'PA',
                              'PA-C',
                              'NP',
                              'APRN',
                              'FNP',
                              'ARNP',
                              'PMHNP',
                              'APN',
                              'FNP-C',
                            ]}
                            typeaheadErrorMessage="Invalid entry. Valid values: MD, DO, PA, PA-C, NP, APRN, FNP, ARNP, PMHNP, APN or FNP-C"
                            shrinkErrorMessage={true}
                            isDisabled={isDisabled}
                            validation={{
                              maxLength: {
                                value: 10,
                                message: VALUE_TOO_LONG,
                              },
                            }}
                          />
                        </div>

                        <div className={`col-sm-6 ${npiSizeClass}`}>
                          <FormField
                            type="text"
                            variant="text"
                            wholeNumber
                            identifier="provider:npi"
                            isDisabled={isDisabled}
                            validation={{
                              minLength: {
                                value: 10,
                                message: VALUE_TOO_SHORT,
                              },
                              maxLength: {
                                value: 10,
                                message: VALUE_TOO_LONG,
                              },
                              pattern: {
                                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                message: ONLY_NUMBERS,
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="p-1">
                      <hr className="text-muted" />
                    </div>
                    <PrescribingLocation
                      isDisabled={isDisabled}
                      onClear={onClear}
                      dispatch={dispatch}
                      formId={form?.uuid}
                      methods={methods}
                      isSubmitted={isSubmitted}
                      registerForm={true}
                    />

                    <div className="my-3 text-center">
                      <button
                        id="initiate-onboarding-button-register"
                        className="btn btn-primary btn-provider m-1"
                        type="submit"
                        onClick={methods.handleSubmit(onSubmit)}
                        disabled={isLoading}
                      >
                        Initiate Onboarding
                        {isLoading && (
                          <>
                            &nbsp;
                            <FontAwesomeIcon
                              icon={faSpinner}
                              color="white"
                              spinPulse={true}
                            />
                          </>
                        )}
                      </button>

                      <button
                        id="cancel-button-register"
                        type="button"
                        className="d-inline-block btn btn-outline-secondary"
                        onClick={onCancelClick}
                        disabled={isDisabled}
                      >
                        Cancel
                      </button>
                    </div>

                    <InfoBar>
                      <LinkComponent
                        isDisabled={isDisabled}
                        label="Click here"
                        href=""
                        data-bs-toggle="modal"
                        data-bs-target="#rcopiaModal"
                      />{' '}
                      to learn more about this process. For questions,{' '}
                      <LinkComponent
                        isDisabled={isDisabled}
                        label="chat with us"
                        href={hyperlinks['support_chat_url']}
                        target="_blank"
                      />{' '}
                      or contact support at (855) 863-1355 Please have your NPI and email address
                      ready.{' '}
                    </InfoBar>
                  </div>
                </form>
              </FormProvider>
            </FormWrapper>
          )}
        </div>
        <div
          className="modal fade"
          id="rcopiaModal"
          tabIndex={-1}
          aria-labelledby="stepsModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header p-2">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="modal-body p-0"
                id="stepsModal"
              >
                <ProviderInstructionViewModal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterProvider;
