export const VALUE_TOO_LONG = 'Value too long';
export const VALUE_TOO_SHORT = 'Value too short';
export const FIELD_MISSING = 'Value is missing or invalid';
export const INVALID_EMAIL = 'Email format invalid';
export const EMAIL_TAKEN = 'Email is already taken';
export const INVALID_DATE = 'Date invalid';
export const INVALID_PHONE = 'Invalid phone number';
export const NON_ASCII_CHARACTER = 'Value must only contain ASCII characters';
export const ONLY_NUMBERS = 'Value must only contain numbers';
export const VALUE_NAME = `Value must only be letters and characters & /. ( ) -  , "'`;
export const VALUE_ADDRESS = `Value allow only characters like &# . , ; : '`;
export const VALUE_CITY = `Value allow only characters like –,.;'&/()`;
export const VALUE_INVALID = `Value invalid`;
export const PICK_LOCATION = `Pick location`;
export const DEFAULT_LOCATION = 'Default location required';
export const DO_NOT_WORRY = "Don't worry - we'll help resolve the problem";
export const TRY_AGAIN_OR_CONTACT_WITH_US =
  'Please try again later. If the error persist please contact with the support';
export const DOES_NOT_MATCH_REQUIRMENTS = 'Password does not meet requirements';
