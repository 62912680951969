import { useFormContext } from 'react-hook-form';
import FormField from '../../containers/FormField/FormField';
import {
  VALUE_TOO_LONG,
  VALUE_TOO_SHORT,
  VALUE_INVALID,
  VALUE_ADDRESS,
  VALUE_CITY,
  ONLY_NUMBERS,
  FIELD_MISSING,
  INVALID_PHONE,
} from './../../constants/validationMessages';

const PrescribingLocationForm = ({
  index,
  registerForm,
  isDisabled,
}: {
  index: number;
  registerForm?: boolean;
  isDisabled?: boolean;
}) => {
  const {
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const dea = watch('provider:location:dea');

  const copyPrev = () => {
    setValue(`${index}:provider:location:dea`, dea);
    trigger(`${index}:provider:location:dea`);
  };

  return (
    <>
      <div className="p-1">
        <hr className="text-muted" />
      </div>
      <div className="col-sm-6 col-md-6">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="text"
          identifier="provider:location:name"
          validation={{
            maxLength: {
              value: 35,
              message: VALUE_TOO_LONG,
            },
          }}
        />
      </div>
      <div className="col-sm-6 col-md-6">
        <div className="form-check form-check-inline ">
          <label className="form-check-label">
            {registerForm ? (
              <FormField
                index={index}
                isDisabled={isDisabled}
                type="radio"
                identifier="provider:location:isDefault"
                radioVariant="horizontal"
                setFormValue={setValue}
                registerForm={registerForm}
              />
            ) : (
              <FormField
                index={index}
                isDisabled={isDisabled}
                type="checkbox"
                identifier="provider:location:isDefault"
                radioVariant="horizontal"
                setFormValue={setValue}
              />
            )}
          </label>
        </div>
      </div>
      <div className="col-sm-6 col-md-3">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="text"
          identifier="provider:location:address1"
          validation={{
            pattern: {
              value: /^[a-z A-Z0-9&#.,;:']+$/g,
              message: VALUE_ADDRESS,
            },
            maxLength: {
              value: 35,
              message: VALUE_TOO_LONG,
            },
          }}
        />
      </div>
      <div className="col-sm-6 col-md-3">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="text"
          identifier="provider:location:address2"
          validation={{
            pattern: {
              value: /^[a-z A-Z0-9&#.,;:']+$/g,
              message: VALUE_ADDRESS,
            },
            maxLength: {
              value: 35,
              message: VALUE_TOO_LONG,
            },
          }}
        />
      </div>
      <div className="col-sm-4 col-md-2">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="text"
          identifier="provider:location:city"
          validation={{
            pattern: {
              value: /^[a-z A-Z,-.;'&/()]*$/,
              message: VALUE_CITY,
            },
            maxLength: {
              value: 35,
              message: VALUE_TOO_LONG,
            },
          }}
        />
      </div>
      <div className="col-sm-4 col-md-2">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="select"
          setFormValue={setValue}
          identifier="provider:location:state"
        />
      </div>
      <div className="col-sm-4 col-md-2">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="text"
          identifier="provider:location:zip"
          validation={{
            pattern: {
              value:
                /^((\d{5}-\d{4})|(\d{5})|(\d{9})|([a-zA-Z]\d[a-zA-Z]\s\d[a-zA-Z]\d)|([a-zA-Z]\d[a-zA-Z]\d[a-zA-Z]\d))$/,
              message: FIELD_MISSING,
            },
            minLength: {
              value: 5,
              message: VALUE_TOO_SHORT,
            },
            maxLength: {
              value: 10,
              message: VALUE_TOO_LONG,
            },
          }}
        />
      </div>
      <div className="col-sm-4 col-md-2">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="text"
          identifier="provider:location:phone"
          variant="phone"
          validation={{
            validate: (value: string) => {
              return !value.includes('_') || !/\d/.test(value) || INVALID_PHONE;
            },
          }}
        />
      </div>
      <div className="col-sm-4 col-md-1">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="text"
          identifier="provider:location:phoneExt"
          validation={{
            pattern: {
              value: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: ONLY_NUMBERS,
            },
            maxLength: {
              value: 5,
              message: VALUE_TOO_LONG,
            },
          }}
        />
      </div>
      <div className="col-sm-6 col-md-3">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="text"
          identifier="provider:location:fax"
          variant="phone"
          validation={{
            validate: (value: string) => {
              return !value.includes('_') || !/\d/.test(value) || INVALID_PHONE;
            },
          }}
        />
      </div>
      <div className="col-sm-6 col-md-3">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="text"
          identifier="provider:location:officeContact"
        />
      </div>
      <div className="col-sm-6 col-md-3">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="text"
          identifier="provider:location:officePhone"
          variant="phone"
          validation={{
            validate: (value: string) => {
              return !value.includes('_') || !/\d/.test(value) || INVALID_PHONE;
            },
          }}
        />
      </div>

      <div className="col-sm-6 col-md-3 ">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="text"
          identifier="provider:location:dea"
          validation={{
            pattern: {
              value: /^[a-zA-Z]{2}[0-9]{7}$/,
              message: VALUE_INVALID,
            },
            minLength: {
              value: 9,
              message: VALUE_TOO_SHORT,
            },
            maxLength: {
              value: 9,
              message: VALUE_TOO_LONG,
            },
          }}
        />
        {index !== 0 && (
          <div
            className={`${
              Object.keys(errors).includes(`${index}:provider:location:dea`) ? 'mt-3' : ''
            } `}
          >
            <a
              id={`${index}-same-as-prev-location-anchor-register`}
              onClick={isDisabled ? undefined : copyPrev}
              className={isDisabled ? 'small link-disabled' : 'small link text-link '}
            >
              Same as previous location
            </a>
          </div>
        )}
      </div>
      <div className="col-sm-4 col-md-2">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="text"
          identifier="provider:location:stateLicense"
        />
      </div>
      <div className="col-sm-4 col-md-2">
        <FormField
          index={index}
          isDisabled={isDisabled}
          type="select"
          setFormValue={setValue}
          identifier="provider:location:LicenseState"
        />
      </div>
    </>
  );
};

export default PrescribingLocationForm;
