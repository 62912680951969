import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import NameHeader from 'components/nameHeader/NameHeader';
import ProgressBar from 'components/Progress/ProgressBar';
import InfoBar from 'components/InfoBar/InfoBar';
import { useAppSelector } from 'hooks/useRedux';
import { workflowSelectors } from 'state/workflow';
import { useLocation } from 'react-router-dom';
import Loader from 'components/Loader/Loader';
import { DO_NOT_WORRY } from 'constants/validationMessages';
import { authSelectors } from 'state/auth';
import { ConfigurableHyperLinkSelector } from 'state/hyperlink/hyperlink.selectors';
import PersonalAssistant from 'components/PersonalAssistant/PersonalAssistant';

const EPCSEnrollment = () => {
  const user = useAppSelector(authSelectors.userSelector);
  const hyperlinks = useAppSelector(ConfigurableHyperLinkSelector);

  const isLoading = useAppSelector(workflowSelectors.loadingSelector);

  const stepSelector = 2;

  const location = useLocation();

  const identifier = location.state?.identifier;

  if (!identifier) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PersonalAssistant />
      <NameHeader
        serviceType={false}
        signOut
      />
      <div className="container mt-2">
        <ProgressBar
          steps={[
            { id: 0, label: 'Initial Onboarding' },
            { id: 1, label: 'Identity Proofing' },
            { id: 2, label: 'EPCS Registration' },
            { id: 3, label: 'Logical Access Control (LAC)' },
          ]}
          activeStep={stepSelector}
        />
        <div className="row g-o">
          <FormWrapper
            text={`Step ${stepSelector + 1} of 4: EPCS Registration`}
            type="provider"
            errorSubTitle={DO_NOT_WORRY}
          >
            <div className="row p-3 pb-0 text-center">
              <div className="col-12">
                {user && <h4 className="text-info">{`Hello Dr. ${user.name}`}</h4>}
                <h5 className="fw-bold">You are currently on Step 3 - EPCS Registration</h5>
                <div className="fa-6x">
                  <FontAwesomeIcon
                    icon={faUserLock}
                    className="text-muted"
                  />
                </div>
                <div className="col-12">
                  <p>
                    Set up your two-factor authentication (2FA) credentials and establish a
                    passphrase. This passphrase will be required for authorizing controlled
                    substance prescriptions.
                  </p>
                  <p>
                    After receiving the verification code, continue the process by using the link
                    found in the original IDP success email from InfinID.
                  </p>
                </div>
              </div>
            </div>

            <InfoBar>
              If it has been more than 7 business days since you completed the Identity Proofing and
              have not received your IDP verification code,{' '}
              <a
                id="chat-with-us-anchor-EPCS-enrollment"
                href={hyperlinks['support_chat_url']}
                target="_blank"
              >
                Chat with us
              </a>{' '}
              or contact support at (855) 863-1355. Please have your NPI and email address ready.{' '}
            </InfoBar>
          </FormWrapper>
        </div>
      </div>
    </>
  );
};

export default EPCSEnrollment;
