import { Button, Form, InputGroup } from 'react-bootstrap';

interface Props {
  setValue: (value: string) => void;
  onSave: () => void;
  value: string;
  placeholder: string;
  errors: string[];
  isDisabled?: boolean;
}
const AddNewItem = ({ value, setValue, onSave, placeholder, errors, isDisabled }: Props) => {
  return (
    <div
      className="row g-0 mb-2"
      data-testid="add-new-item-panel"
    >
      <div className="col-12 col-md-10 col-lg-6">
        <InputGroup
          size="sm"
          hasValidation
        >
          <Form.Control
            value={value}
            onChange={(e: { target: { value: string } }) => setValue(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') onSave();
            }}
            type="text"
            isInvalid={errors.length != 0}
            id={`${placeholder.replaceAll(' ', '-').toLowerCase()}-input`}
            data-testid="add-new-item-input"
            placeholder={placeholder}
            disabled={isDisabled}
          />

          <Button
            onClick={onSave}
            id={`${placeholder.replaceAll(' ', '-').toLowerCase()}-save-button`}
            data-testid="add-new-item-save"
            disabled={isDisabled}
          >
            Save
          </Button>
          <Form.Control.Feedback type="invalid">
            {errors.map((error, index) => (
              <div
                data-testid="add-new-item-error"
                key={index}
              >
                {error}
              </div>
            ))}
          </Form.Control.Feedback>
        </InputGroup>
      </div>
    </div>
  );
};

export default AddNewItem;
