import FormWrapper from 'components/FormWrapper/FormWrapper';
import NameHeader from 'components/nameHeader/NameHeader';
import PersonalAssistant from 'components/PersonalAssistant/PersonalAssistant';
import ProgressBar from 'components/Progress/ProgressBar';

const EPCSInviteFailed = () => {
  return (
    <>
      <PersonalAssistant />

      <NameHeader
        serviceType={false}
        signOut
      />
      <div className="container mt-2">
        <ProgressBar
          steps={[
            { id: 0, label: 'Initial Onboarding' },
            { id: 1, label: 'Identity Proofing' },
            { id: 2, label: 'EPCS Registration' },
            { id: 3, label: 'Logical Access Control (LAC)' },
          ]}
          activeStep={0}
        />
        <div className="row g-o">
          <FormWrapper
            text={`Step 1 of 4: Initial Onboarding`}
            type="provider"
          />
        </div>
      </div>
    </>
  );
};

export default EPCSInviteFailed;
