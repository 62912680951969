import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { REMS_LAC_EMAIL_SUCCESS } from 'constants/identifierAndActions';
import SuccessComponent from 'components/SuccessComponent/SuccessComponent';
import LACForm from './LACForm';
import NameHeader from 'components/nameHeader/NameHeader';
import ProgressBar from 'components/Progress/ProgressBar';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { workflowActions, workflowSelectors } from 'state/workflow';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import LoadingContent from 'components/LoadingContent/LoadingContent';
import { DO_NOT_WORRY } from 'constants/validationMessages';
import PersonalAssistant from 'components/PersonalAssistant/PersonalAssistant';

const LAC = () => {
  const isLoading = useAppSelector(workflowSelectors.loadingSelector);
  const location = useLocation();
  const identifier = location.state?.identifier;

  const error = useAppSelector(workflowSelectors.errorSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(workflowActions.setLoading(true));
  }, [error]);

  if (!identifier) {
    return null;
  }

  const stepSelector = 3;

  const renderForm = () => {
    if (identifier === REMS_LAC_EMAIL_SUCCESS) {
      return <SuccessComponent title={'Email has been sent'} />;
    }

    return <LACForm />;
  };

  return (
    <>
      <PersonalAssistant />

      <NameHeader
        serviceType={false}
        signOut
      />
      <div className="container mt-2">
        <ProgressBar
          steps={[
            { id: 0, label: 'Initial Onboarding' },
            { id: 1, label: 'Identity Proofing' },
            { id: 2, label: 'EPCS Registration' },
            { id: 3, label: 'Logical Access Control (LAC)' },
          ]}
          activeStep={stepSelector}
        />
      </div>
      <div className="container mt-2">
        <div className="row g-o">
          <FormWrapper
            text={`Step ${stepSelector + 1} of 4: Logical Access Control (LAC)`}
            type="provider"
            errorSubTitle={DO_NOT_WORRY}
          >
            {!isLoading ? renderForm() : <LoadingContent />}
          </FormWrapper>
        </div>
      </div>
    </>
  );
};

export default LAC;
