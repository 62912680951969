import { Outlet, useOutlet } from 'react-router-dom';
import { faIdCard } from '@fortawesome/free-regular-svg-icons';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoBar from 'components/InfoBar/InfoBar';
import moveStep from 'hooks/moveStep';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { workflowActions, workflowSelectors } from 'state/workflow';
import { authSelectors } from 'state/auth';
import { useEffect } from 'react';
import { ConfigurableHyperLinkSelector } from 'state/hyperlink/hyperlink.selectors';

interface IDPFormProps {
  identifier: string;
}
const IDPForm = ({ identifier }: IDPFormProps) => {
  const outlet = useOutlet();
  const user = useAppSelector(authSelectors.userSelector);
  const hyperlinks = useAppSelector(ConfigurableHyperLinkSelector);
  const dispatch = useAppDispatch();

  const { onNextClick } = moveStep();
  const form = useAppSelector(workflowSelectors.workflowForm);

  const handleClick = () => {
    dispatch(workflowActions.setLoading(true));
    onNextClick(form.uuid, {});
  };

  useEffect(() => {
    dispatch(workflowActions.setLoading(false));
  }, []);

  if (!identifier) {
    return null;
  }

  if (outlet) {
    return <Outlet />;
  }

  return (
    <>
      <div className="row p-3 text-center">
        <div className="col-12">
          {user && <h4 className="text-info">{`Hello Dr. ${user.name}`}</h4>}
          <h5 className="fw-bold">You are currently on Step 2 - Identity Proofing (IDP)</h5>
          <div className="fa-6x">
            <FontAwesomeIcon
              icon={faIdCard}
              className="text-muted"
            />
          </div>
          <div className="col-12">
            <div>
              Complete the identity proofing process provided by DrFirst. The process requires
              submitting identification documents, such as a driver's license, passport, or other
              government-issued ID, along with a photo of yourself for verification.
              <br />
              If it has been more than 24 hours since you submitted your registration and you have
              not received the invitation, use the link below to resend the IDP Invite.
            </div>
            <button
              className="btn btn-primary btn-provider m-3 "
              type="submit"
              onClick={handleClick}
              id="resend-idp-invite-button"
            >
              <FontAwesomeIcon icon={faEnvelopeOpenText} /> &nbsp; Resend IDP Invite
            </button>
          </div>
        </div>
      </div>
      <InfoBar>
        If you have not received the Identity Proofing email or need additional assistance,{' '}
        <a
          id="chat-with-us-anchor-IDP-form"
          href={hyperlinks['support_chat_url']}
          target="_blank"
        >
          chat with us
        </a>{' '}
        or contact support at (855) 863-1355. Please have your NPI and email address ready.
      </InfoBar>
    </>
  );
};

export default IDPForm;
