import {
  FieldErrorsImpl,
  useFormContext,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useAppSelector } from 'hooks/useRedux';
import { formsSelectors } from 'state/forms';
import ErrorBadge from '../ErrorBadge/ErrorBadge';
import { ValuesType } from 'state/forms/forms.reducer';
import { FIELD_MISSING, PICK_LOCATION } from 'constants/validationMessages';
import { isIdentifier } from 'typescript';

export interface Props {
  label: string;
  isRequired?: boolean;
  setValue?: UseFormSetValue<ValuesType>;
  watch?: UseFormWatch<ValuesType>;
  trigger?: UseFormTrigger<ValuesType>;
  errors?: Partial<
    FieldErrorsImpl<{
      [x: string]: NonNullable<string | string[]>;
    }>
  >;
  formKey: string;
  choices: any;
  register: UseFormRegister<ValuesType>;
  hideReq?: boolean;
  isDisabled?: boolean;
}
interface Choice {
  [x: string]: {
    eng: string;
  };
}

const SelectField = ({
  label,
  isRequired,
  choices,
  errors,
  formKey,
  register,
  hideReq,
  isDisabled,
}: Props) => {
  const state = useAppSelector(formsSelectors.stateSelector);
  const { trigger, setValue, watch } = useFormContext();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue && setValue(formKey, e.target.value);
    trigger && trigger(formKey);
  };

  const setHideReq = () => {
    if (hideReq) return true;
  };

  const redBorderError =
    errors && errors[formKey] && 'border border-danger border-opacity-25 border-2';

  const validationBudge = errors && errors[formKey] && (
    <ErrorBadge
      errors={errors}
      formKey={formKey}
    />
  );

  const newChoices = choices.options && [...choices.options, ...choices.staticOptions];
  const isLocationSelect = choices.options ? true : false;

  const renderOptions = () => {
    if (newChoices) {
      return newChoices.map((choice: Choice, id: number) => {
        const key = Object.keys(choice)[0];
        return (
          <option
            data-testid="option"
            value={key}
            key={id}
          >
            {choice[key].eng}
          </option>
        );
      });
    } else {
      return choices.eng.map((choice: string, id: number) => {
        return (
          <option
            data-testid="option"
            value={choice}
            key={id}
          >
            {choice}
          </option>
        );
      });
    }
  };

  return (
    <>
      <label data-testid="label">
        {label}
        {!setHideReq() && <span className="text-danger">*</span>}
      </label>
      <select
        id={formKey}
        {...register(formKey, {
          validate: (value) => {
            if (isRequired && !value && setHideReq()) {
              return PICK_LOCATION;
            }
            if (isRequired && !value) {
              return FIELD_MISSING;
            }
          },
        })}
        value={watch ? watch(formKey) : state}
        data-testid="selectField"
        className={`form-select ${redBorderError}`}
        onChange={handleChange}
        name={formKey}
        disabled={isDisabled}
      >
        {!isLocationSelect && <option value="">Choose {label}</option>}
        {renderOptions()}
      </select>
      {validationBudge}
    </>
  );
};

export default SelectField;
