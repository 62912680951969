import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConfigurableHyperLinkService } from 'services/Resources';
import { hyperlinkActions } from '.';
import { HyperLinkValues } from './hyperlink.reducer';

export const getHyperlinks = createAsyncThunk(
  'hyperlink/getHyperlinks',
  async (payload: any, { dispatch }) => {
    const options = {
      params: {
        enabled: undefined,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await ConfigurableHyperLinkService.get(
      options,
      (res: HyperLinkValues) => {
        dispatch(hyperlinkActions.setHyperlinkValues(res));
      },
      (err: any) => {
        console.log(err);
      },
    );
  },
);
