import { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { ServiceCheckDetails } from './healthCheck.types';
import {
  httpCheck,
  imageCheck,
  reportHealthCheckClosed,
  reportHealthCheckFailed,
} from './helpers/checks';
import { HealthCheckException } from './exceptions/HealthCheckException';
import { ConfigurableHyperLinkSelector } from 'state/hyperlink/hyperlink.selectors';
import { useAppSelector } from 'hooks/useRedux';

const REMS_API = process.env.REACT_APP_API_URL + '/touch_me/';
const EPCS_DR_FIRST_URL = process.env.REACT_APP_EPCS_DR_FIRST_URL;
const RCOPIA_URL = process.env.REACT_APP_RCOPIA_URL;

const HEALTH_CHECK_TIMEOUT_IN_MS = 3000;

const SERVICES_TO_CHECK: ServiceCheckDetails[] = [
  {
    name: 'REMS_API',
    url: REMS_API,
    checkType: 'http',
  },
  {
    name: 'EPCS_DR_FIRST',
    url: EPCS_DR_FIRST_URL,
    checkType: 'image',
  },
  {
    name: 'RCOPIA',
    url: RCOPIA_URL,
    checkType: 'http',
  },
];

function HealthCheck() {
  const [poorConnection, setPoorConnection] = useState(false);
  const hyperlinks = useAppSelector(ConfigurableHyperLinkSelector);

  /**
   * Useeffect that will call all of the services in the array and check if they are up or down.
   * The check will set the state of the poorConnection to true if any of the services are down, or cannot be connected within configured timeout value.
   */
  useEffect(() => {
    const checkHealth = async () => {
      const promises = SERVICES_TO_CHECK.map(async (service) => {
        if (!service || !service?.url) {
          console.warn(`Missing service URL for ${service?.name}`);
          return;
        }

        /**
         * Check if the service is reachable or not based on the checkType.
         */
        try {
          if (service.checkType === 'http') {
            await httpCheck(service.url, HEALTH_CHECK_TIMEOUT_IN_MS);
          } else if (service.checkType === 'image') {
            await imageCheck(service.url, HEALTH_CHECK_TIMEOUT_IN_MS);
          }
        } catch (error) {
          try {
            const date = new Date();
            localStorage.setItem(
              `health-check-failed-${date.toISOString()}`,
              JSON.stringify({
                service,
                error,
              }),
            );
          } catch (error) {
            console.error('Error saving to local storage', error);
          }

          throw new HealthCheckException(service);
        }
      });

      /**
       * Report problem to UI server, so that the issue can be tracked/logged.
       */
      try {
        await Promise.all(promises);
      } catch (error) {
        if (error instanceof HealthCheckException) {
          reportHealthCheckFailed(error.serviceName, error.serviceUrl);
        }
        setPoorConnection(true);
      }
    };

    checkHealth();
  }, []);

  const closeHealthCheckAlert = () => {
    setPoorConnection(false);
    const date = new Date();
    localStorage.setItem(`health-check-closed-${date.toISOString()}`, 'true');
    reportHealthCheckClosed();
  };

  if (!poorConnection) {
    return null;
  }

  return (
    <Alert variant="danger">
      <div
        className="d-flex"
        style={{
          alignItems: 'center',
        }}
      >
        <div>
          <h5>
            <strong>Connectivity Issues</strong>
          </h5>

          <p>
            You may experience issues with the site due to firewall or security software settings.
            If the problem persists, please contact your local admin, or contact{' '}
            <a
              href={hyperlinks['support_chat_url']}
              id="health-check-alert-support-link"
              target="_blank"
            >
              support by chat
            </a>{' '}
            or phone at (855) 863-1355.
          </p>
        </div>
        <div>
          <Button
            id="close-health-check-alert"
            data-testid="close-health-check-alert"
            variant="danger"
            onClick={closeHealthCheckAlert}
          >
            Continue
          </Button>
        </div>
      </div>
    </Alert>
  );
}

export default HealthCheck;
