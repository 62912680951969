import React, { useEffect } from 'react';
import { FieldErrorsImpl, UseFormRegister, useWatch } from 'react-hook-form';
import type { ValuesType } from 'state/forms/forms.reducer';
import ErrorBadge from 'components/ErrorBadge/ErrorBadge';
import { isASCII } from 'constants/validationRules';

export interface Props {
  placeholder?: string;
  required?: boolean;
  title?: string;
  style?: React.CSSProperties;
  formKey: string;
  register: UseFormRegister<ValuesType>;
  setValue?: (value: string) => void;
  isDisabled?: boolean;
  maxLength?: number;
  validation?: object & {
    validate?: (value: string) => boolean | string;
  };
  errors?: Partial<
    FieldErrorsImpl<{
      [x: string]: NonNullable<string | string[]>;
    }>
  >;
}

const TextareaField = ({
  title,
  required,
  placeholder,
  style,
  formKey,
  register,
  isDisabled,
  validation,
  errors,
  maxLength,
  setValue,
}: Props) => {
  const redBorderError =
    errors && errors[formKey] && 'border border-danger border-opacity-25 border-2';

  const value = useWatch({
    name: formKey,
  });

  const validationBudge = errors && errors[formKey] && (
    <ErrorBadge
      errors={errors}
      formKey={formKey}
    />
  );

  return (
    <>
      {validationBudge}
      <div className={`form-control wrapper-textarea small ${redBorderError}`}>
        <label
          data-testid="label"
          className="second-heading"
        >
          {title}
          {required && <span className="text-danger">*</span>}
        </label>

        <textarea
          {...(formKey && {
            ...register(formKey, {
              required,
              ...validation,
              validate: {
                external: validation?.validate ?? (() => true),
                ascii: isASCII,
                maxLength: (value) => {
                  if (!maxLength) return true;

                  return value.length <= maxLength || `Maximum character number is ${maxLength}`;
                },
              },
            }),
          })}
          id={formKey}
          maxLength={maxLength}
          rows={3}
          data-testid="textarea"
          className="textarea-style"
          placeholder={placeholder}
          style={style}
          disabled={isDisabled}
        ></textarea>
        {maxLength && typeof value !== 'undefined' && (
          <div style={{ textAlign: 'right', color: '#6c757d' }}>
            {value?.length}/{maxLength}
          </div>
        )}
      </div>
    </>
  );
};

export default TextareaField;
