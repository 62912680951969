import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type HyperLinkValues = Partial<{
  configurable_hyperlink_1: string;
  configurable_hyperlink_2: string;
  get_started_support_modal_enabled: boolean;
  schedule_appointment_url: string;
  support_chat_url: string;
  lac_step_4_call_to_action_v2: boolean;
  lac_step_4_admin_validation: boolean;
  special_instructions_character_count_validation: boolean;
  signature_field_non_gray_scale: boolean;
  responsive_password_validation: boolean;
  suffix_rename_to_professional_designation: boolean;
}>;

type InitialState = {
  values: HyperLinkValues;
};

const initialState: InitialState = {
  values: {
    configurable_hyperlink_1: '',
    configurable_hyperlink_2: '',
    get_started_support_modal_enabled: true,
    schedule_appointment_url:
      'https://outlook.office365.com/owa/calendar/JazzPharmaceuticalsOnboardingSupport@DrFirst365.onmicrosoft.com/bookings/',
    support_chat_url: 'https://support.erx-jazzpharma.com',
    lac_step_4_call_to_action_v2: false,
    lac_step_4_admin_validation: false,
    special_instructions_character_count_validation: false,
    signature_field_non_gray_scale: false,
    responsive_password_validation: false,
    suffix_rename_to_professional_designation: false,
  },
};

const hyperlinkSlice = createSlice({
  name: 'hyperlink',

  initialState,
  reducers: {
    setHyperlinkValues(state, action: PayloadAction<HyperLinkValues>) {
      if (typeof action.payload === 'object') {
        state.values = {
          ...state.values,
          ...action.payload,
        };
      }
    },
    resetHyperlinkValues(state) {
      state.values = initialState.values;
    },
  },
});

export const reducerHyperlinkActions = hyperlinkSlice.actions;
export default hyperlinkSlice.reducer;
