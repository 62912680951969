import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import PrescribingLocationForm from './PrescribingForm';
import { AppDispatch } from 'state/store';

interface PrescribingLocationProps {
  onClear: any;
  card?: boolean;
  registerForm?: boolean;
  isDisabled?: boolean;
  dispatch: AppDispatch;
  formId: string;
  methods: any;
  isSubmitted: boolean;
}

function PrescribingLocation({
  onClear,
  card,
  registerForm,
  isDisabled,
  dispatch,
  formId,
  methods,
  isSubmitted,
}: PrescribingLocationProps) {
  const [locationForm, setLocationForm] = useState<number[]>([0]);

  const [index, setIndex] = useState(1);

  const addLocationForm = () => {
    setLocationForm([...locationForm, index]);
    setIndex(index + 1);
  };
  const removeLocationForm = async () => {
    setLocationForm(locationForm.slice(0, -1));
    await onClear(dispatch, formId, methods, isSubmitted, index - 1, registerForm);
    setIndex(index - 1);
  };

  return (
    <>
      <div className="container mt-2">
        <div className="row g-o">
          <div className="col-12">
            <div className={card ? 'card mb-2' : ''}>
              <div className="row px-2 py-1">
                <div className="col-12">
                  <h5 className="py-2 text-info">Prescribing Location(s)</h5>
                </div>
                <div className="col-12 pb-3">
                  This must reflect the location address to be included on an electronic
                  prescription. i.e. where the patient is visiting you. Please ensure you setup all
                  location(s) where you'll be prescribing REMS drugs.
                </div>

                {locationForm.map((index: number) => {
                  return (
                    <PrescribingLocationForm
                      key={index}
                      index={index}
                      registerForm={registerForm}
                      isDisabled={isDisabled}
                    />
                  );
                })}
              </div>

              <div className="row px-3 py-1">
                <div className="p-1">
                  <hr className="text-muted" />
                </div>
                <div className="link-primary">
                  <i
                    id="add-location-i-register"
                    className={
                      !isDisabled
                        ? 'fa fa-plus cursor-pointer btn btn-link location-form'
                        : 'btn btn-link location-form add-remove-disable'
                    }
                    aria-hidden="true"
                    onClick={!isDisabled ? addLocationForm : undefined}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="primary"
                    />
                    &nbsp;Add Another Location
                  </i>
                  {locationForm.length > 1 && (
                    <i
                      id="remove-location-i-register"
                      className={
                        !isDisabled
                          ? 'fa fa-plus cursor-pointer btn btn-link location-form'
                          : 'btn btn-link location-form add-remove-disable'
                      }
                      aria-hidden="true"
                      onClick={!isDisabled ? removeLocationForm : undefined}
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="primary"
                      />
                      &nbsp;Remove Last Location
                    </i>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrescribingLocation;
