import { NON_ASCII_CHARACTER } from './validationMessages';

export const emailRegex =
  /^[_A-Za-z0-9\-+]+(\.[_A-Za-z0-9\-+]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;

export const isASCII = (value: string) => {
  if (value === '') return true;
  // Required is checked in another validation rule
  if (typeof value !== 'string') return true;

  return ![...value].some((char) => char.charCodeAt(0) > 127) || NON_ASCII_CHARACTER;
};
