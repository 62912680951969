export const isPasswordCorrectLength = (password: string) => {
  return password.length >= 12;
};

export const containsAlphaCharacter = (password: string) => {
  return /[A-Z]/.test(password);
};

export const matchesWitchConfirmPassword = (password: string, confirmPassword: string) => {
  return password === confirmPassword;
};

export interface UserData {
  name: string;
  middleName?: string;
  lastName: string;
  NPI: number;
}

export const containsUserData = (password: string, userData: UserData) => {
  const NPIString = userData.NPI.toString();
  const fieldsToCheck = [
    userData.name.toLowerCase(),
    userData.middleName?.toLowerCase(),
    userData.lastName.toLowerCase(),
    NPIString,
  ];
  return fieldsToCheck.some((field) => field && password.toLowerCase().includes(field));
};
