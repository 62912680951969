import axios from 'axios';
import { getTimestamp } from './cacheWorkaround';

export const httpCheck = async (serviceUrl: string, timeout: number) => {
  const response = await axios.get(serviceUrl, {
    timeout,
    withCredentials: false,
    params: {
      cacheWorkaround: getTimestamp(),
    },
  });

  if (response.status >= 400) {
    throw new Error(`Service could not be reached within ${timeout}ms`);
  }
};

export const imageCheck = async (serviceUrl: string, timeout: number) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    const timer = setTimeout(() => {
      img.src = '';
      reject(new Error(`Service could not be reached within ${timeout}ms`));
    }, timeout);

    img.onload = () => {
      clearTimeout(timer);
      resolve(true);
    };
    img.onerror = (error) => {
      clearTimeout(timer);
      reject(error);
    };

    img.src = serviceUrl + `?cacheWorkaround=${getTimestamp()}`;
  });
};

export const reportHealthCheckFailed = async (
  serviceName: string | undefined,
  serviceUrl: string | undefined,
) => {
  axios.get('/api/health-check-status', {
    params: {
      status: 'failed',
      service_name: serviceName,
      service_url: serviceUrl,
      cacheWorkaround: getTimestamp(),
    },
  });
};

export const reportHealthCheckClosed = async () => {
  axios.get('/api/health-check-closed', {
    params: {
      action: 'clicked_continue_button',
      cacheWorkaround: getTimestamp(),
    },
  });
};
