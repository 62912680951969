import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export interface props {
  text: string;
  type?: 'primary' | 'warning';
  iconRight?: boolean;
  iconLeft?: boolean;
  icon?: IconDefinition;
}

const WorkflowNavigationButton = ({ text, iconRight, iconLeft, icon, type = 'primary' }: props) => {
  return (
    <span className="text-decoration-none px-1">
      <button
        id={`${text.replaceAll(' ', '-').toLowerCase()}-button`}
        className={`btn btn-${type}`}
        name={type}
      >
        {icon && iconLeft && <FontAwesomeIcon icon={icon} />}
        {text} {icon && iconRight && <FontAwesomeIcon icon={icon} />}
      </button>
    </span>
  );
};

export default WorkflowNavigationButton;
