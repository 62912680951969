import { ServiceCheckDetails } from '../healthCheck.types';

export class HealthCheckException extends Error {
  serviceName: string | undefined;
  serviceUrl: string | undefined;

  constructor(service: ServiceCheckDetails) {
    super(`Health check failed for service: ${service?.name} at ${service?.url}`);

    this.serviceName = service?.name;
    this.serviceUrl = service?.url;
  }
}
