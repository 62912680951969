import { useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import { FieldErrorsImpl } from 'react-hook-form';

interface ErrorBadgeProps {
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: NonNullable<string | string[]>;
    }>
  >;

  formKey: string;
  shrink?: boolean;
}
const ErrorBadge = ({ errors, formKey, shrink = false }: ErrorBadgeProps) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const setTransparent = hover ? { opacity: 0.1 } : undefined;

  return (
    <Badge
      style={{
        ...setTransparent,
        zIndex: 10,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - textWrap type is missing from BadgeProps
        textWrap: shrink ? 'wrap' : 'normal',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      bg="danger"
      bsPrefix="badge position-absolute z-3"
    >
      {errors[formKey]?.message}
    </Badge>
  );
};

export default ErrorBadge;
