import DosingRow from 'components/DosingRow/DosingRow';

const TitratedDosing = () => {
  return (
    <div
      className="tab-pane"
      id="pills-titrated-2"
      role="tabpanel"
      aria-labelledby="pills-titrated-tab-2"
      tabIndex={1}
    >
      <h5 className="pt-3 text-info text-center">Titrated XYWAV Dosing: Titrate to Effect</h5>
      <h6 className="fw-bold">1 time a night dosing (IH patients)</h6>
      <div className="col-7 offset-5 fw-bold small text-muted">
        Number of Days (at each titration step)
      </div>
      <DosingRow
        wideField={1}
        title="Starting Dose"
        fields={{
          doses: ['rems:titratedDosing:effect:ih:startingDose:dose'],
          timeOfDrugTaking: 'rems:titratedDosing:effect:ih:startingDose:dose:numOfDays',
        }}
      />
      <DosingRow
        wideField={1}
        title="1st Titration"
        fields={{
          doses: ['rems:titratedDosing:effect:ih:1stTitration:dose'],
          timeOfDrugTaking: 'rems:titratedDosing:effect:ih:1stTitration:dose:numOfDays',
        }}
      />
      <DosingRow
        wideField={1}
        title="2nd Titration"
        fields={{
          doses: ['rems:titratedDosing:effect:ih:2ndTitration:dose'],
          timeOfDrugTaking: 'rems:titratedDosing:effect:ih:2ndTitration:dose:numOfDays',
        }}
      />
      <DosingRow
        wideField={1}
        title="3rd Titration"
        fields={{
          doses: ['rems:titratedDosing:effect:ih:3rdTitration:dose'],
          timeOfDrugTaking: 'rems:titratedDosing:effect:ih:3rdTitration:dose:numOfDays',
        }}
      />

      <div className="col-12 small px-2 pt-3 fw-bold">
        <div>
          Doses may be divided equally or unequally and the first dose taken at bedtime and the
          second dose taken 2.5 to 4 hours later.
        </div>
        <div>
          *For pediatric patients who sleep more than 8 hours per night, the first dose of XYWAV may
          be given at bedtime or after an initial period of sleep.
        </div>
        <div>
          **If XYWAV is used in patients 7 years of age and older who weigh less than 20 kg, a lower
          starting dosage, lower maximum weekly dosage increases, and lower total maximum nightly
          dosage should be considered.
        </div>
      </div>
    </div>
  );
};

export default TitratedDosing;
