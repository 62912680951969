import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/useRedux';
import { authActions, authSelectors } from 'state/auth';
import { FIELD_MISSING, VALUE_TOO_LONG } from 'constants/validationMessages';
import ErrorBadge from 'components/ErrorBadge/ErrorBadge';
import Alert from 'react-bootstrap/Alert';
import store, { useAppDispatch } from 'state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface DefaultValues {
  password: string;
  confirmPassword: string;
}
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = () => {
  const navigate = useNavigate();
  const error = useAppSelector(authSelectors.resetPasswordErrorSelector);
  const query = useQuery();
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState(false);

  const defaultValues = { password: '', confirmPassword: '' };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(authActions.setResetPasswordError(''));
  }, []);

  const setInputStyles = (key: 'password' | 'confirmPassword') =>
    errors[key] ? 'form-control border border-danger border-opacity-25 border-2' : 'form-control';

  const onSubmit = async (data: DefaultValues) => {
    setIsLoader(true);
    if (password !== confirmPassword) {
      dispatch(authActions.setResetPasswordError('Passwords do not match'));
      setIsLoader(false);
      return;
    }
    await dispatch(
      authActions.resetPassword({ token: query.get('token') || '', password: data.password }),
    );
    if (!store.getState().auth.resetPasswordError) {
      setSuccess(true);
      reset();
    }
    setIsLoader(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-rems w-100 d-flex ">
        <div className="container pt-5 mt-5">
          <div className="col-10 offset-1 col-md-4 offset-md-4">
            <div className="card mb-2 shadow">
              <div className="card-header bg-info text-white fw-bolder text-center">
                <img
                  src={require('./../../../assets/logo/jazz_logo_white.png')}
                  className="img-fluid d-inline-block align-text-middle"
                />
                <div className="pt-3">
                  <h5 className="fw-bold">RESET PASSWORD</h5>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="row px-3 py-1">
                  <div
                    style={{ display: `${success ? 'none' : 'block'}` }}
                    className="col-12 "
                  >
                    Set new password value for {query.get('email')}
                  </div>
                  <div className="col-12 mt-2">
                    {!error && success && <Alert variant={'success'}>Password changed</Alert>}
                    {error && (
                      <Alert variant={'danger'}>
                        {Array.isArray(error)
                          ? error.map((errorMessage, key) => {
                              return <div key={key}>{errorMessage}</div>;
                            })
                          : error}
                      </Alert>
                    )}
                  </div>
                  <div
                    className="col-12 mt-2"
                    style={{ display: `${success ? 'none' : 'block'}` }}
                  >
                    <label>
                      New Password<span className="text-danger">*</span>
                    </label>
                    <input
                      id="reset-password-input"
                      disabled={success}
                      type="password"
                      className={setInputStyles('password')}
                      {...register('password', {
                        required: {
                          value: true,
                          message: FIELD_MISSING,
                        },
                        maxLength: {
                          value: 35,
                          message: VALUE_TOO_LONG,
                        },
                        onChange: () => {
                          if (error) {
                            dispatch(authActions.setResetPasswordError(''));
                          }
                        },
                      })}
                    />
                    <ErrorBadge
                      errors={errors}
                      formKey="password"
                    />
                  </div>
                  <div
                    className="col-12 mt-2"
                    style={{ display: `${success ? 'none' : 'block'}` }}
                  >
                    <label>
                      Confirm New Password<span className="text-danger">*</span>
                    </label>
                    <input
                      id="confirm-password-reset-input"
                      disabled={success}
                      type="password"
                      className={setInputStyles('confirmPassword')}
                      {...register('confirmPassword', {
                        required: {
                          value: true,
                          message: FIELD_MISSING,
                        },
                        maxLength: {
                          value: 35,
                          message: VALUE_TOO_LONG,
                        },
                        onChange: () => {
                          if (error) {
                            dispatch(authActions.setResetPasswordError(''));
                          }
                        },
                      })}
                    />
                    <ErrorBadge
                      errors={errors}
                      formKey="confirmPassword"
                    />
                  </div>
                  <div className="col-12 my-2 text-center">
                    <button
                      id="reset-password-button"
                      onClick={() => {
                        if (success) {
                          navigate('/');
                        }
                      }}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {success ? 'Go to Login page' : 'Reset Password'}{' '}
                      {isLoader && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          color="white"
                          className="mx-1"
                          spinPulse={true}
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="row text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default ResetPassword;
