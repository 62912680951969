import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import moveStep from 'hooks/moveStep';
import { serviceTypeActions } from 'state/serviceType/index';
import { reducerWorkflowActions } from 'state/workflow/workflow.reducer';
import { formsActions, formsSelectors } from 'state/forms';
import PrescribingLocation from 'components/PrescribingLocation/PrescribingLocation';
import FormField from 'containers/FormField/FormField';
import DefaultModal from 'components/Modal/Modal';
import { workflowActions, workflowSelectors } from 'state/workflow';
import Loader from 'components/Loader/Loader';
import setAlertMessage from 'helpers/setAlertMessage';
import onClear from 'helpers/onClear';
import { ConfigurableHyperLinkSelector } from 'state/hyperlink/hyperlink.selectors';

const CLOSING_TEXT = 'Closing will delete any data entered in the current form';
const SUCCESS_TEXT = 'Your data has been successfully saved';

type FormObject = {
  [key: string]: FormObject | string;
};

const ChooseLocationAndMedication = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const identifier = location.state?.identifier;

  const { workflow } = useAppSelector(workflowSelectors.workflowSelector);
  const formValues = useAppSelector(formsSelectors.formSelector);
  const locationDefaultSelector = useAppSelector(workflowSelectors.locationDefaultSelector);
  const error = useAppSelector(workflowSelectors.errorSelector);
  const isLoading = useAppSelector(workflowSelectors.loadingSelector);
  const hyperlinks = useAppSelector(ConfigurableHyperLinkSelector);

  const [locationLoader, setLocationLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isOpenRemoveModal, setIsOpenClearModal] = useState(false);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const formId = workflow?.form?.uuid;
  const workflowStatus = workflow?.status;
  const methods = useForm<typeof formValues>();

  const { onNextClick } = moveStep();

  const valueLocation = methods.watch('provider:location');
  const medType = methods.watch('provider:chosenMedication');

  const alertMessage = setAlertMessage(error);

  useEffect(() => {
    if (locationDefaultSelector) {
      methods.setValue('provider:location', locationDefaultSelector);
    }
  }, [locationDefaultSelector]);

  useEffect(() => {
    if (valueLocation === 'add_new_location') {
      setIsDisabled(false);
      setIsSubmitted(false);
      setOpenModal(true);
    }
  }, [valueLocation]);

  useEffect(() => {
    if (methods.formState.isSubmitted) {
      setIsSubmitted(true);
    }
  }, [methods.formState.isSubmitted]);

  useEffect(() => {
    const checkModal = async () => {
      if (openModal) {
        if (workflowStatus === 'initialized') {
          setLocationLoader(false);
          setOpenModal(false);
          setIsOpenConfirmationModal(true);
          methods.setValue('provider:location', locationDefaultSelector);
          dispatch(formsActions.resetValues());
          await onClear(dispatch, formId, methods, isSubmitted);
        }
        if (error) {
          setIsDisabled(false);
          setLocationLoader(false);
        }
      }
    };
    checkModal();
  }, [workflowStatus, error]);

  const onSubmit = (data: typeof formValues) => {
    setIsDisabled(true);
    setLocationLoader(true);
    if (valueLocation && valueLocation !== 'Add New Location...') {
      dispatch(serviceTypeActions.setServiceType(medType));
      dispatch(reducerWorkflowActions.select(valueLocation));
      methods.setValue('provider:chosenMedication', medType);
    }
    if (error) {
      dispatch(formsActions.setLocationFormValues({ formId, values: data }));
      dispatch(workflowActions.workflowRetryLatestActionStep());
    } else {
      dispatch(formsActions.setLocationFormValues({ formId, values: data }));
      onNextClick(formId, data, false);
      setIsSubmitted(false);
    }
  };

  const checkEmpty = (obj: FormObject) => {
    for (const key in obj) {
      if (key.includes('provider:location:')) {
        if (obj[key] instanceof Object === true) {
          if (checkEmpty(obj[key] as FormObject) === false) return false;
        } else {
          if (obj[key] && obj[key].length !== 0) return false;
        }
      }
    }
    return true;
  };

  const onClose = () => {
    const isEmpty = checkEmpty(methods.getValues());
    if (!isEmpty) {
      setIsOpenClearModal(true);
    } else {
      onCloseAndClear();
    }
  };

  const onCloseAndClear = async () => {
    await onClear(dispatch, formId, methods, isSubmitted);
    setLocationLoader(false);
    methods.setValue('provider:location', locationDefaultSelector);
    setOpenModal(false);
    setIsOpenClearModal(false);
    setIsSubmitted(false);
    if (error) {
      dispatch(workflowActions.workflowPreviousStep());
      dispatch(workflowActions.setError(false));
    }
  };

  if (!identifier) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="container pt-5 mt-5">
      <div className="col-10 offset-1 col-md-6 offset-md-3 ">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <div className="card mb-2 shadow pb-1 position-relative">
              <div className="card-header bg-info text-white    fw-bolder text-center">
                <img
                  src={require('./../../assets/logo/jazz_logo_white.png')}
                  className="img-fluid d-inline-block align-text-middle"
                />
              </div>
              <div className="card-body p-0 ">
                <div className="row g-0 text-center border-bottom p-3 bg-light">
                  <div className="col-12">
                    <h5 className="p-2">Pick your current prescribing location?</h5>
                  </div>
                  <div className="col-10 offset-1">
                    <FormField
                      type="select"
                      identifier="provider:location"
                      hideReq
                    />
                  </div>
                </div>
                <div className="row g-0 text-center">
                  <div className="col-12">
                    <h5 className="p-2">Which drug are you looking to prescribe?</h5>
                  </div>
                  <div className="col-12 col-lg-6 p-3">
                    <button
                      id="xywav-button"
                      data-testid="xywav"
                      className="border border-2 d-block w-100 border-xywav p-1 bg-white"
                      onClick={() => methods.setValue('provider:chosenMedication', 'xywav')}
                    >
                      <img
                        src={require('./../../assets/logo/xywav.jpg')}
                        className="img-fluid-med"
                      />
                    </button>
                  </div>
                  <div className="col-12 col-lg-6 p-3">
                    <button
                      id="xyrem-button"
                      data-testid="xyrem"
                      className="border border-2 d-block w-100 border-xyrem p-1 bg-white"
                      onClick={() => methods.setValue('provider:chosenMedication', 'xyrem')}
                    >
                      <img
                        src={require('./../../assets/logo/xyrem.jpg')}
                        className="img-fluid-med"
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div className="small px-2 d-inline-block ">
                For XYWAV and XYREM REMS certified prescribers only. Complete by clicking{' '}
                <a
                  id="for-certified-prescribers-anchor"
                  href={hyperlinks['configurable_hyperlink_1'] || ''}
                  target="_blank"
                >
                  {' '}
                  here
                </a>
              </div>
            </div>
          </FormProvider>
        </form>
      </div>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Modal
            backdrop={isDisabled ? 'static' : true}
            keyboard={isDisabled ? false : true}
            className="modal-xl"
            show={openModal}
            onHide={onClose}
          >
            <Modal.Header />

            {error && (
              <div className="d-flex justify-content-center mt-3">
                <Alert variant={'danger'}>{alertMessage}</Alert>
              </div>
            )}

            <Modal.Body>
              <PrescribingLocation
                isDisabled={isDisabled}
                onClear={onClear}
                dispatch={dispatch}
                formId={formId}
                methods={methods}
                isSubmitted={isSubmitted}
                card
              />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button
                variant="secondary"
                onClick={onClose}
                className="align-text-middle"
                disabled={locationLoader}
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={locationLoader}
                className="align-text-middle"
              >
                Save
                {locationLoader && (
                  <>
                    &nbsp;
                    <FontAwesomeIcon
                      icon={faSpinner}
                      color="white"
                      spinPulse={true}
                    />
                  </>
                )}
              </Button>
            </Modal.Footer>
          </Modal>
          <DefaultModal
            handleClose={() => setIsOpenClearModal(false)}
            handleSubmit={() => onCloseAndClear()}
            show={isOpenRemoveModal}
            text={CLOSING_TEXT}
            cancelButtonText="Cancel"
            continueButtonText="Proceed"
          />
          <DefaultModal
            handleClose={() => setIsOpenConfirmationModal(false)}
            show={isOpenConfirmationModal}
            text={SUCCESS_TEXT}
            classNameText="d-block text-center text-success fw-bolder"
            cancelButtonText="Continue"
          />
        </FormProvider>
      </form>
    </div>
  );
};

export default ChooseLocationAndMedication;
