import CloseButton from 'react-bootstrap/CloseButton';
import Button from 'react-bootstrap/Button';
import './PersonalAssistant.scss';
import { useAppSelector } from 'hooks/useRedux';
import { ConfigurableHyperLinkSelector } from 'state/hyperlink/hyperlink.selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import userHeadset from './../../containers/views/ProviderInstruction/icons/user-headset.svg';
import { useEffect, useState } from 'react';

interface Props {
  autoopenTimeInSeconds?: number;
}
function PersonalAssistant({ autoopenTimeInSeconds = 0 }) {
  const [toastVisible, setToastVisible] = useState(false);
  const hyperlinks = useAppSelector(ConfigurableHyperLinkSelector);

  const hideToast = () => {
    setToastVisible(false);
  };

  const showToast = () => {
    setToastVisible(true);
  };

  useEffect(() => {
    if (autoopenTimeInSeconds === 0) return;

    const interval = setTimeout(() => {
      setToastVisible(true);
    }, autoopenTimeInSeconds * 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="personal-assistant-container d-flex flex-column align-items-end">
      {toastVisible && (
        <div
          className="personal-assistant-toast bg-light rounded p-4 shadow-sm"
          style={{ width: 330 }}
        >
          <div className="d-flex">
            <div className="pa-toast-text">
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Need Help?
              </h5>
              <p>
                Registering to ePrescribe controlled substances is a one-time process. We recommend
                reaching out to us at (855) 863-1255, chatting with a live agent, or scheduling an
                appointment at your convenience.
              </p>
            </div>
            <div className="pa-toast-close">
              <CloseButton onClick={hideToast} />
            </div>
          </div>
          <hr />
          <div className="pa-options">
            <Button
              id="support-modal-schedule-appointment"
              className="w-100 mt-2"
              as="a"
              variant="primary"
              size="sm"
              href={hyperlinks['schedule_appointment_url']}
              target="_blank"
            >
              <FontAwesomeIcon icon={faCalendarDays} />
              &nbsp; Schedule an Appointment
            </Button>
            <Button
              id="support-modal-support-link"
              className="w-100 mt-2"
              as="a"
              variant="primary"
              size="sm"
              href={hyperlinks['support_chat_url']}
              target="_blank"
            >
              <img
                src={userHeadset}
                width={14}
                height={14}
                style={{
                  transform: 'translateY(-2px)',
                }}
              />{' '}
              &nbsp; Live Chat Support
            </Button>
            <Button
              id="support-modal-continue-registration"
              className="w-100 mt-2"
              variant="text"
              size="sm"
              style={{ color: 'var(--bs-primary)' }}
              onClick={hideToast}
            >
              Continue my Registration
            </Button>
          </div>
        </div>
      )}

      <Button
        className="personal-assistant-icon btn-primary d-flex align-items-center justify-content-center mt-2 rounded-circle"
        onClick={showToast}
      >
        <img
          src={userHeadset}
          width={30}
          height={30}
        />
      </Button>
    </div>
  );
}

export default PersonalAssistant;
