import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type userSelector } from 'state/auth/auth.selectors';

interface Props {
  user: ReturnType<typeof userSelector>;
}

function LACTextV1({ user }: Props) {
  return (
    <>
      <div className="col-12">
        {user && <h4 className="text-info">{`Hello Dr. ${user.name}`}</h4>}
        <h5 className="fw-bold">You are one step away from being fully enrolled</h5>
        <div className="fa-6x">
          <FontAwesomeIcon
            icon={faUserGroup}
            className="text-muted"
          />
        </div>
        <div className="col-12">
          <div>
            {
              'The DEA requires that an acting administrator, or equivalent person, grants you access to electronically prescribe controlled substances. This individual will verify that you are authorized to prescribe controlled substances under the DEA license number listed in the Logical Access Control (LAC) User Interface. This is typically the practice or hospital administrator.'
            }
          </div>
        </div>
      </div>
      <div className="col-12 pt-3 text-start">
        <h5 className="py-3 text-info">{'Logical Access Control (LAC) User Details'}</h5>
      </div>
      <div className="col-12 text-start">
        <p className="fw-bold">
          You will require someone from your practice to serve as the second authorizing user.
          Please enter the details for the second user below. This user will receive an email
          containing instructions on how to complete the final step. Note that you will need to be
          present with the user to enter your TFA credentials.
        </p>
      </div>
    </>
  );
}

export default LACTextV1;
