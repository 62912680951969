import { type userSelector } from 'state/auth/auth.selectors';

interface Props {
  user: ReturnType<typeof userSelector>;
}

function LACTextV2({ user }: Props) {
  return (
    <>
      <div className="col-12">
        {user && <h4 className="text-info">{`Hello Dr. ${user.name}`}</h4>}
        <h5 className="fw-bold">You are one step away from being fully enrolled</h5>
        <h4
          className="text-info mt-5"
          style={{ textAlign: 'left' }}
        >
          Notify Your Facility Administrator
        </h4>
        <div
          className="col-12 mt-4"
          style={{ textAlign: 'left', fontWeight: 'bold' }}
        >
          <h5 style={{ fontWeight: 'bold' }}>
            Per DEA requirements, enter the contact details of the individual authorized to grant
            final access for prescribing. This person is typically the practice or hospital
            administrator. They will receive an email containing instructions on how to complete the
            final step.{' '}
          </h5>
          <h5 style={{ fontWeight: 'bold' }}>
            Once the admin receives the access request, they will verify you are authorized to
            prescribe controlled substances and your DEA license #. Upon successful verification,
            the admin will grant you prescribing access.
          </h5>
        </div>
        <h5
          className="text-danger"
          style={{ fontWeight: 'bold', textAlign: 'left' }}
        >
          NOTE: You will need to be present to enter your two-factor authentication credentials.
        </h5>
      </div>

      <div className="col-12 text-start mt-5"></div>
    </>
  );
}

export default LACTextV2;
