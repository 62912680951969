import { Outlet, useLocation, useOutlet } from 'react-router-dom';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoBar from 'components/InfoBar/InfoBar';
import FormField from 'containers/FormField/FormField';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { workflowActions, workflowSelectors } from 'state/workflow';
import { formsSelectors } from 'state/forms';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import moveStep from 'hooks/moveStep';
import { INVALID_EMAIL, VALUE_NAME, VALUE_TOO_LONG } from 'constants/validationMessages';
import { authSelectors } from 'state/auth';
import { emailRegex } from 'constants/validationRules';
import { ConfigurableHyperLinkSelector } from 'state/hyperlink/hyperlink.selectors';
import LACTextV1 from './components/LACTextV1';
import LACTextV2 from './components/LACTextV2';
import { useEffect, useMemo } from 'react';

const SELF_AUTHORIZE_ERROR = 'Cannot self authorize. Please enter an Admin.';

const LAC = () => {
  const { workflow } = useAppSelector(workflowSelectors.workflowSelector);
  const hyperlinks = useAppSelector(ConfigurableHyperLinkSelector);
  const formValues = useAppSelector(formsSelectors.formSelector);
  const outlet = useOutlet();

  const formId = workflow?.form?.uuid;
  const methods = useForm<typeof formValues>({
    mode: hyperlinks?.['lac_step_4_admin_validation'] ? 'onChange' : 'onSubmit',
  });

  const user = useAppSelector(authSelectors.userSelector);
  const dispatch = useAppDispatch();

  const { onNextClick } = moveStep();

  const onSubmit = (data: typeof formValues) => {
    dispatch(workflowActions.setLoading(true));
    onNextClick(formId, data, false);
  };

  const location = useLocation();
  const identifier = location.state?.identifier;

  const [firstNameValue, lastNameValue, emailValue] = useWatch({
    name: ['authorizingUser:firstName', 'authorizingUser:lastName', 'authorizingUser:email'],
    control: methods.control,
  });

  const [nameValid, emailValid] = useMemo(() => {
    if (hyperlinks?.['lac_step_4_admin_validation'] !== true) return [true, true];
    if (!user) return [true, true];

    let nameValid = true;
    let emailValid = true;

    const { first_name, last_name, email } = user;

    if (
      typeof email === 'string' &&
      typeof emailValue === 'string' &&
      email.toLocaleLowerCase() === emailValue.toLowerCase()
    ) {
      emailValid = false;
    }

    if (
      typeof first_name === 'string' &&
      typeof firstNameValue === 'string' &&
      typeof last_name === 'string' &&
      typeof lastNameValue === 'string' &&
      first_name.toLocaleLowerCase() === firstNameValue.toLowerCase() &&
      last_name.toLocaleLowerCase() === lastNameValue.toLowerCase()
    ) {
      nameValid = false;
    }

    return [nameValid, emailValid];
  }, [hyperlinks, user, firstNameValue, lastNameValue, emailValue]);

  const submitButtonEnabled = useMemo(() => {
    if (hyperlinks?.['lac_step_4_admin_validation'] !== true) return true;
    return nameValid && emailValid;
  }, [hyperlinks, nameValid, emailValid]);

  useEffect(() => {
    methods.trigger(['authorizingUser:firstName', 'authorizingUser:lastName']);
  }, [nameValid]);

  useEffect(() => {
    methods.trigger(['authorizingUser:email']);
  }, [emailValid]);

  if (!identifier) {
    return null;
  }

  if (outlet) {
    return <Outlet />;
  }

  return (
    <>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <div className="row p-3 text-center">
            {hyperlinks?.['lac_step_4_call_to_action_v2'] ? (
              <LACTextV2 user={user} />
            ) : (
              <LACTextV1 user={user} />
            )}
            <div className="col-sm-6 col-md-3 text-start">
              <FormField
                type="text"
                identifier="authorizingUser:firstName"
                labelOverride={
                  hyperlinks?.['lac_step_4_call_to_action_v2'] ? 'Admin. First Name' : undefined
                }
                validation={{
                  pattern: {
                    value: /^[a-z A-Z&)/('.,-]*$/,
                    message: VALUE_NAME,
                  },
                  maxLength: {
                    value: 35,
                    message: VALUE_TOO_LONG,
                  },
                  validate: () => {
                    if (hyperlinks?.['lac_step_4_admin_validation'] !== true) return true;

                    if (!nameValid) {
                      return SELF_AUTHORIZE_ERROR;
                    }

                    return true;
                  },
                }}
              />
            </div>
            <div className="col-sm-6 col-md-3 text-start">
              <FormField
                type="text"
                identifier="authorizingUser:lastName"
                labelOverride={
                  hyperlinks?.['lac_step_4_call_to_action_v2'] ? 'Admin. Last Name' : undefined
                }
                validation={{
                  pattern: {
                    value: /^[a-z A-Z&)/('.,-]*$/,
                    message: VALUE_NAME,
                  },
                  maxLength: {
                    value: 35,
                    message: VALUE_TOO_LONG,
                  },
                  validate: () => {
                    if (hyperlinks?.['lac_step_4_admin_validation'] !== true) return true;

                    if (!nameValid) {
                      return SELF_AUTHORIZE_ERROR;
                    }

                    return true;
                  },
                }}
              />
            </div>
            <div className="col-sm-6 col-md-3 text-start">
              <FormField
                type="text"
                identifier="authorizingUser:email"
                labelOverride={
                  hyperlinks?.['lac_step_4_call_to_action_v2'] ? 'Admin. Email' : undefined
                }
                validation={{
                  pattern: {
                    value: emailRegex,
                    message: INVALID_EMAIL,
                  },
                  validate: () => {
                    if (hyperlinks?.['lac_step_4_admin_validation'] !== true) return true;

                    if (!emailValid) {
                      return SELF_AUTHORIZE_ERROR;
                    }

                    return true;
                  },
                }}
              />
            </div>
            <div className="col-sm-6 col-md-3 text-start pt-2">
              <button
                id="send-lac-email-button"
                className="btn btn-primary m-3"
                type="submit"
                disabled={!submitButtonEnabled}
                onClick={methods.handleSubmit(onSubmit)}
              >
                <FontAwesomeIcon icon={faEnvelope} />
                &nbsp;
                {hyperlinks?.['lac_step_4_call_to_action_v2']
                  ? 'Send Authorization Request'
                  : 'Send LAC Email'}
              </button>
            </div>
          </div>
          <InfoBar>
            If you have questions or need additional assistance,{' '}
            <a
              id="chat-with-us-anchor-LAC-form"
              href={hyperlinks['support_chat_url']}
              target="_blank"
            >
              chat with us
            </a>{' '}
            or contact support at (855) 863-1355. Please have your NPI and email address ready.
          </InfoBar>
        </FormProvider>
      </form>
    </>
  );
};

export default LAC;
