import './PasswordChecklist.scss';
import CheckIcon from './assets/CheckIcon';
import ErrorIcon from './assets/ErrorIcon';
import {
  containsAlphaCharacter,
  containsUserData,
  isPasswordCorrectLength,
  matchesWitchConfirmPassword,
  UserData,
} from './passwordChecks';

interface PasswordChecklistProps {
  password: string;
  confirmPassword: string;
  userData: UserData;
}

const PasswordChecklist = ({ password, confirmPassword, userData }: PasswordChecklistProps) => {
  return (
    <div className="password-checklist-container col-sm-6 col-md-3">
      <ul style={{ paddingLeft: '8px' }}>
        <li className="password-checklist-element">
          <div className="check-icon">
            {isPasswordCorrectLength(password) ? <CheckIcon /> : <ErrorIcon />}
          </div>
          <div className="password-checklist-text">12 characters or longer.</div>
        </li>
        <li className="password-checklist-element">
          <div className="check-icon">
            {containsAlphaCharacter(password) ? <CheckIcon /> : <ErrorIcon />}
          </div>
          <div className="password-checklist-text">Contains at least one uppercase character.</div>
        </li>
        <li className="password-checklist-element">
          <div className="check-icon">
            {matchesWitchConfirmPassword(password, confirmPassword) ? <CheckIcon /> : <ErrorIcon />}
          </div>
          <div className="password-checklist-text">Matches with the confirm password.</div>
        </li>
        <li className="password-checklist-element">
          <div className="check-icon">
            {!containsUserData(password, userData) ? <CheckIcon /> : <ErrorIcon />}
          </div>
          <div className="password-checklist-text">Does not contain any provider details.</div>
        </li>
      </ul>
    </div>
  );
};

export default PasswordChecklist;
