import {
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import InputMask from 'react-input-mask';
import { DefaultPropsInterface } from 'containers/FormField/Data';
import { ValuesType } from 'state/forms/forms.reducer';
import { FIELD_MISSING } from 'constants/validationMessages';
import ErrorBadge from '../ErrorBadge/ErrorBadge';
import { useState } from 'react';

interface TextFieldProps {
  className?: string;
  variant?: 'text' | 'number' | 'phone' | 'password' | 'numberAsText';
  field?: DefaultPropsInterface;
  units?: string;
  readOnly?: boolean;
  required?: boolean;
  wholeNumber?: boolean;
  trigger?: UseFormTrigger<ValuesType>;
  hideReq?: boolean;
  title?: string;
  step?: string | number;
  register: UseFormRegister<ValuesType>;
  setFormValue?: UseFormSetValue<ValuesType>;
  formKey: string;
  errors?: Partial<
    FieldErrorsImpl<{
      [x: string]: NonNullable<string | string[]>;
    }>
  >;
  setValue?: (value: string) => void;
  validation?: object & {
    validate?: (value: string) => boolean | string;
  };
  isDisabled?: boolean;
  typeahead?: string[];
  typeaheadErrorMessage?: string;
  shrinkErrorMessage?: boolean;
}

const TextField = ({
  variant,
  hideReq,
  className,
  units,
  title,
  required,
  readOnly = false,
  register,
  formKey,
  wholeNumber,
  step,
  errors,
  setValue,
  validation,
  isDisabled,
  typeahead,
  typeaheadErrorMessage = 'Invalid entry.',
  shrinkErrorMessage,
}: TextFieldProps) => {
  const { setValue: setFormValue } = useFormContext();
  const [typeaheadVisible, setTypeaheadVisible] = useState(false);

  const value = useWatch({
    name: formKey,
  });

  const redBorderError =
    errors && errors[formKey] && 'border border-danger border-opacity-25 border-2';

  const validationBudge = errors && errors[formKey] && (
    <ErrorBadge
      shrink={shrinkErrorMessage}
      errors={errors}
      formKey={formKey}
    />
  );

  if (variant === 'phone') {
    return (
      <>
        <label>
          {title}
          {required && <span className="text-danger">*</span>}
        </label>
        <InputMask
          id={formKey}
          data-testid="phoneField"
          {...register(formKey, {
            required: {
              value: required ? true : false,
              message: FIELD_MISSING,
            },
            ...validation,
          })}
          className={`${className} ${redBorderError}`}
          mask="(999) 999-9999"
          type="tel"
          disabled={isDisabled}
        />
        {validationBudge}
      </>
    );
  }
  if (variant === 'numberAsText') {
    return (
      <>
        <label>
          {title ? title : <span>&nbsp;</span>}
          {required && !hideReq && <span className="text-danger">*</span>}
        </label>
        <div className="input-group">
          <input
            id={formKey}
            step={step || '.001'}
            readOnly={readOnly}
            lang="en"
            {...(formKey && {
              ...register(formKey, {
                validate: {
                  required: (value) => {
                    if (required && !value) {
                      return FIELD_MISSING;
                    }
                  },
                  valueGreaterThanZero: (value) => {
                    if (value && value <= 0) {
                      return FIELD_MISSING;
                    }
                  },
                  pattern(value) {
                    if (wholeNumber) {
                      if (value && !value.match(/^-?\d+$/)) {
                        return FIELD_MISSING;
                      }
                    }
                    if (!wholeNumber && value && !value.match(/^-?\d+(\.\d{1,3})?$/)) {
                      return FIELD_MISSING;
                    }
                  },
                },
                ...validation,
                onChange: (e) => {
                  setValue && setValue(e.target.value);
                },
              }),
            })}
            disabled={isDisabled}
            required={required}
            className={`${className} ${redBorderError}`}
            data-testid="numberField"
            onKeyPress={(event) => {
              if (event.key === 'Enter') event.preventDefault();
            }}
          />
          {units && <span className="input-group-text">{units}</span>}
        </div>
        {validationBudge}
      </>
    );
  }
  if (variant === 'number') {
    return (
      <>
        <label>
          {title ? title : <span>&nbsp;</span>}
          {required && !hideReq && <span className="text-danger">*</span>}
        </label>
        <div className="input-group">
          <input
            id={formKey}
            step={step || '.001'}
            readOnly={readOnly}
            lang="en"
            disabled={isDisabled}
            {...(formKey && {
              ...register(formKey, {
                validate: {
                  required: (value) => {
                    if (required && !value) {
                      return FIELD_MISSING;
                    }
                  },
                  valueGreaterThanZero: (value) => {
                    if (value && value <= 0) {
                      return FIELD_MISSING;
                    }
                  },
                  pattern(value) {
                    if (wholeNumber) {
                      if (value && !value.match(/^-?\d+$/)) {
                        return FIELD_MISSING;
                      }
                    }
                    if (!wholeNumber && value && !value.match(/^-?\d+(\.\d{1,3})?$/)) {
                      return FIELD_MISSING;
                    }
                  },
                },
                ...validation,
                onChange: (e) => {
                  const regex = /^\d+(\.\d{0,3})?$/;
                  if (!regex.test(e.target.value) && e.target.value !== '') {
                    setFormValue(formKey, Number(e.target.value).toFixed(3));
                    setValue && setValue(e.target.value);
                  }
                },
              }),
            })}
            required={required}
            className={`${className} ${redBorderError}`}
            data-testid="numberField"
            type="number"
            onKeyPress={(event) => {
              if (event.key === 'Enter') event.preventDefault();
            }}
          />
          {units && <span className="input-group-text">{units}</span>}
        </div>
        {validationBudge}
      </>
    );
  }

  const renderTypeahead = () => {
    let typeaheadMatches: string[] = [];

    if (!typeahead) return null;
    if (typeof value === 'undefined') {
      typeaheadMatches = typeahead;
    } else {
      typeaheadMatches = typeahead.filter((item) =>
        item.toUpperCase().startsWith(value.toUpperCase()),
      );
    }

    if (typeaheadMatches.length === 0) return null;

    return (
      <div className="typeahead-container">
        {typeaheadMatches.map((item) => {
          return (
            <div
              key={item}
              className="typeahead-item"
              onMouseDown={() => {
                setFormValue(formKey, item, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
            >
              {item}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {title && (
        <label>
          {title}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <input
        autoComplete={typeahead ? 'off' : 'on'}
        disabled={isDisabled}
        {...(formKey && {
          ...register(formKey, {
            ...validation,
            validate: {
              internal: (value) => {
                if (required && !value.trim()) {
                  return FIELD_MISSING;
                }

                if (typeahead && !typeahead.includes(value)) {
                  return typeaheadErrorMessage;
                }

                return true;
              },
              external: validation?.validate ?? (() => true),
            },
          }),
          onFocus: () => {
            setTypeaheadVisible(true);
          },
          onBlur: () => {
            setTypeaheadVisible(false);
          },
        })}
        id={formKey}
        className={`${className} ${redBorderError}`}
        data-testid="textField"
        type={variant === 'password' ? variant : undefined}
      />
      {validationBudge}
      {typeahead && typeaheadVisible && renderTypeahead()}
    </>
  );
};

export default TextField;
